var terminal_active = false;


document.addEventListener('keydown', function(event) {
    if (terminal_active) {
        handleTerminalInput(event);
        return;
    }

    const selected = document.querySelector('.selected');
    if (event.key === 'ArrowUp') {
        event.preventDefault(); // Prevent scrolling
        if (selected.previousElementSibling) {
            selected.classList.remove('selected');
            selected.previousElementSibling.classList.add('selected');
        }
    } else if (event.key === 'ArrowDown') {
        event.preventDefault(); // Prevent scrolling
        if (selected.nextElementSibling) {
            selected.classList.remove('selected');
            selected.nextElementSibling.classList.add('selected');
        }
    } else if (event.key === 'Enter') {
        const link = selected.querySelector('a');
        if (link) {
            window.open(link.href, '_blank');
        }
    }
});

function showTerminal() {
    document.getElementById('mini-about').innerHTML = 'Minimal BASH-like line editing is supported. For the first word, TAB lists possible command completions.<br>Anywhere else TAB lists possible device or file completions. Press ESC to return to the menu.';
    document.getElementById('boot-loader').style.display = 'none';
    document.getElementById('terminal').style.display = 'block';
    document.getElementById('terminal-input').focus();
    terminal_active = true;
}

function hideTerminal() {
    document.getElementById('mini-about').innerHTML = "Welcome to Devadutta Ghat's Personal Website.";
    document.getElementById('boot-loader').style.display = 'block';
    document.getElementById('terminal').style.display = 'none';
    terminal_active = false;
}

document.addEventListener('keydown', function(event) {
    if (event.key === 'Escape') {
        event.preventDefault();
        hideTerminal();
    }
    
    if (terminal_active) return;

    if (event.key === 'c') {
        event.preventDefault();
        showTerminal();
    }

    if (event.key === 'e') {
        event.preventDefault();
        document.getElementById('pk').text = 'public keys <-- Use these keys to send a pgp message to me.';
    }

});

// Handle tap events on mobile
document.querySelectorAll('.option').forEach(option => {
    option.addEventListener('click', function() {
        document.querySelector('.selected').classList.remove('selected');
        this.classList.add('selected');
        const link = this.querySelector('a');
        if (link) {
            window.open(link.href, '_blank');
        }
    });
});

const commands = ['hello', 'joke', 'fortune', 'dance', 'exit', 'cowsay'];

window.hello = function() {
    return 'd-_-g Hello';
};

window.joke = function() {
    return 'yes.';
};

window.fortune = function() {
    const fortunes = [
        'A journey of a thousand miles begins with a single step.',
        'Your talents will be recognized and suitably rewarded.',
        'The greatest risk is not taking one.',
        'A pleasant surprise is waiting for you.',
        'Now is the time to try something new.',
        'Good luck is the result of good planning.',
        'You will receive unexpected support from an acquaintance.',
        'Your hard work will pay off in the near future.',
        'A dream you have will come true.',
        'Your ability to juggle many tasks will take you far.',
        'An exciting opportunity lies ahead of you.',
        'Success is a journey, not a destination.',
        'Your creativity will serve you well today.',
        'A faithful friend is a strong defense.',
        'Patience is a virtue that brings great reward.'
    ];
    const luckyNumbers = Array.from({length: 6}, () => Math.floor(Math.random() * 49) + 1);
    const fortune = fortunes[Math.floor(Math.random() * fortunes.length)];
    return `${fortune}\nLucky numbers: ${luckyNumbers.join(', ')}`;
};

window.dance = function() {
    return 'no';
};

window.exit = function() {
    hideTerminal();
};

window.cowsay = function(text = "Moo!") {
    const message = text || "Moo!";
    return `<pre>
     ${'-'.repeat(message.length + 2)}
    < ${message} >
     ${'-'.repeat(message.length + 2)}
            \\   ^__^
             \\  (oo)\\_______
                (__)\\       )\\/\\
                    ||----w |
                    ||     ||
</pre>`;
};

function nl2br(str) {
    return str.replace(/\n/g, "<br>");
}

function handleTerminalInput(event) {
    const input = document.getElementById('terminal-input');
    
    if (event.key === 'Tab') {
        event.preventDefault();
        const output = document.createElement('p');
        output.textContent = 'Available commands: ' + commands.join(', ');
        document.getElementById('terminal').insertBefore(output, input.parentNode);
    } else if (event.key === 'Enter') {
        executeCommand(input.value.trim());
        input.value = '';
    }
}

function executeCommand(command) {
    let output;
    const [cmd, ...args] = command.split(' ');
    if (commands.includes(cmd) && typeof window[cmd] === 'function') {
        output = window[cmd](args.join(' '));
    } else {
        output = `Command not found: ${cmd}. Try ${commands.join(', ')}.`;
    }
    const outputElement = document.createElement('p');
    outputElement.innerHTML = "dash> " + nl2br(output);
    document.getElementById('terminal').insertBefore(outputElement, document.getElementById('terminal-input').parentNode);
}
